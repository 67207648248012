<template>
  <div class="why-us">
    <div class="container container--no-pad">
      <h2 class="text-center title-h2">{{ title }}</h2>
      <div class="subtitle text-center">{{ subtitle }}</div>
      <div class="main-img-wrap position-relative text-md-center no_mobile">
        <span class="d-inline-block col-md-6 col-7">
          <WpImage :key="image?.node?.sourceUrl" :img="image?.node" :set-webp="true"/>
          <div class="mat-row mat-row--right" v-for="item in right">
            <div class="mat-row__inner d-flex position-relative">
              <div class="mat-info col-auto px-2">
                <div class="mat-info__title">{{ item.title }}</div>
                <div class="mat-info__text" v-html="item.text"></div>
              </div>
              <div class="icon col-auto">
                <WpImage :key="item?.image?.node?.sourceUrl" :img="item.image.node" :set-webp="true"/>
              </div>
            </div>
          </div>

          <div class="mat-row mat-row--left" v-for="item in left">
            <div class="mat-row__inner d-flex position-relative">
              <div class="icon col-auto px-2">
                <WpImage :img="item.image.node" :set-webp="true"/>
              </div>
              <div class="mat-info col-auto">
                <div class="mat-info__title">{{ item.title }}</div>
                <div class="mat-info__text" v-html="item.text"></div>
              </div>
            </div>
          </div>
        </span>
      </div>
      <div
          class="main-img-wrap main-img-bg text-md-center mobile_only position-relative overflow-hidden"
          data-sticky-container
          ref="mobileContainer"
      >
        <van-sticky offset-top="70">

          <nuxt-img id="mobileWhyUs" class="sticky-mat" :src="imageMobile?.node.sourceUrl+'.webp'"
                    :alt="imageMobile?.node?.altText"/>
        </van-sticky>
        <span class="position-relative d-inline-block col-md-5 col-7">
          <div
              class="mat-row text-center"
              data-aos-offset="150"
              data-aos="zoom-in-left"
              data-aos-delay="0.5"
              data-aos-duration="1000"
              data-aos-easing="ease"
              data-aos-once="true"
              v-for="(item, index) in right"
              :key="index"
          >
            <div class="mat-row__inner d-flex position-relative mobile-column flex-column-reverse">
              <div class="mat-info col-auto px-2">
                <div class="mat-info__title">{{ item.title }}</div>
                <div class="mat-info__text" v-html="item.text"></div>
              </div>
              <div class="icon col-auto">
                <WpImage :img="item.image?.node" :set-webp="true"/>
              </div>
            </div>
          </div>
          <div
              class="mat-row text-center"
              data-aos-offset="150"
              data-aos="zoom-in-left"
              data-aos-delay="0.5"
              data-aos-duration="1000"
              data-aos-easing="ease"
              data-aos-once="true"
              v-for="(item, index) in left"
              :key="index"
          >
            <div class="mat-row__inner d-flex position-relative mobile-column flex-column-reverse">
              <div class="mat-info col-auto">
                <div class="mat-info__title">{{ item.title }}</div>
                <div class="mat-info__text" v-html="item.text"></div>
              </div>
              <div class="icon col-auto">
                <WpImage :img="item.image.node" :set-webp="true"/>
              </div>
            </div>
          </div>
        </span>
      </div>
    </div>
  </div>
</template>
<script setup>
import Sticky from "sticky-js";

const {currentPage} = useGlobalStore();
const {title, subtitle, image, imageMobile, left, right} = currentPage?.template?.homepage?.whyUs;
let sticky;
const {$anime} = useNuxtApp();
const isMobile = ref(true);
const mobileContainer = ref(null);
onUpdated(() => {
  sticky.update();
});
onMounted(() => {
  if (process.client && window) {
    isMobile.value = window.innerWidth < 800;
  }
  sticky = new Sticky("#mobileWhyUs", {
    wrap: false,
    marginTop: 120
  });
  if (process.client && window) {
    document.addEventListener("scroll", () => {
      sticky.update();
    });
  }
  if (!isMobile.value) {

    $anime({
      targets: ".mat-row:nth-of-type(1)",
      right: !isMobile.value ? "-2%" : "5%",
      duration: 1000,
      delay: 500,
      easing: "easeInOutQuad",
    });
    $anime({
      targets: ".mat-row:nth-of-type(2)",
      right: !isMobile.value ? "0%" : "5%",
      duration: 1000,
      delay: 1000,
      easing: "easeInOutQuad",
    });
    $anime({
      targets: ".mat-row:nth-of-type(3)",
      right: !isMobile.value ? "5%" : "5%",
      duration: 1000,
      delay: 1500,
      easing: "easeInOutQuad",
    });
    $anime({
      targets: ".mat-row:nth-of-type(4)",
      left: !isMobile.value ? "0%" : "5%",
      duration: 1000,
      delay: 500,
      easing: "easeInOutQuad",
    });
    $anime({
      targets: ".mat-row:nth-of-type(5)",
      left: "5%",
      duration: 1000,
      delay: 1000,
      easing: "easeInOutQuad",
    });
    $anime({
      targets: ".mat-row:nth-of-type(6)",
      left: !isMobile.value ? "-1%" : "5%",
      duration: 1000,
      delay: 1500,
      easing: "easeInOutQuad",

    });
  }
});
</script>
<style lang="scss" scoped>
.main-img-bg {
  min-height: 500px;
}
</style>
